// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

//resets, variables, mixins, and some utility CSS classes


input[type=checkbox]:checked ~ #overlay {
  visibility: visible;
}

input[type=checkbox] {
  display: none;
}

html {
  scroll-behavior: smooth;
}


// Encrypted page styling template


// <!doctype html>
// <html class="staticrypt-html">
// <head>
//     <meta charset="utf-8">
//     <title>Protected Page</title>
//     <meta name="viewport" content="width=device-width, initial-scale=1">

//     <!-- do not cache this page -->
//     <meta http-equiv="cache-control" content="max-age=0"/>
//     <meta http-equiv="cache-control" content="no-cache"/>
//     <meta http-equiv="expires" content="0"/>
//     <meta http-equiv="expires" content="Tue, 01 Jan 1980 1:00:00 GMT"/>
//     <meta http-equiv="pragma" content="no-cache"/>

//     <style>
//         .staticrypt-hr {
//             margin-top: 20px;
//             margin-bottom: 20px;
//             border: 0;
//             border-top: 1px solid #eee;
//         }

//         .staticrypt-page {
//             width: 360px;
//             padding: 8% 0 0;
//             margin: auto;
//             box-sizing: border-box;
//         }

//         .staticrypt-form {
//             position: relative;
//             z-index: 1;
//             background: #FFFFFF;
//             max-width: 360px;
//             margin: 0 auto 100px;
//             padding: 45px;
//             text-align: center;
//             box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
//         }

//         .staticrypt-form input[type="password"] {
//             outline: 0;
//             background: #f2f2f2;
//             width: 100%;
//             border: 0;
//             margin: 0 0 15px;
//             padding: 15px;
//             box-sizing: border-box;
//             font-size: 14px;
//         }

//         .staticrypt-form .staticrypt-decrypt-button {
//             text-transform: uppercase;
//             font-weight:bold;
//             outline: 0;
//             background: #fbb622;
//             width: 100%;
//             border: 0;
//             padding: 15px;
//             color: #000;
//             font-size: 14px;
//             cursor: pointer;
//         }

//         .staticrypt-form .staticrypt-decrypt-button:hover, .staticrypt-form .staticrypt-decrypt-button:active, .staticrypt-form .staticrypt-decrypt-button:focus {
//             background: #43A047;
//         }

//         .staticrypt-html {
//             height: 100%;
//         }

//         .staticrypt-body {
//             height: 100%;
//             margin: 0;
//         }

//         .staticrypt-content {
//             height: 100%;
//             margin-bottom: 1em;
//             background: #222; /* fallback for old browsers */
//             /* background: -webkit-linear-gradient(right, #76b852, #8DC26F);
//             background: -moz-linear-gradient(right, #76b852, #8DC26F);
//             background: -o-linear-gradient(right, #76b852, #8DC26F);
//             background: linear-gradient(to left, #76b852, #8DC26F); */
//             font-family: "Arial", sans-serif;
//             -webkit-font-smoothing: antialiased;
//             -moz-osx-font-smoothing: grayscale;
//         }

//         .staticrypt-instructions {
//             margin-top: -1em;
//             margin-bottom: 1em;
//         }

//         .staticrypt-title {
//             font-size: 1.5em;
//         }

//         .staticrypt-footer {
//             position: fixed;
//             height: 20px;
//             font-size: 16px;
//             padding: 2px;
//             bottom: 0;
//             left: 0;
//             right: 0;
//             margin-bottom: 0;
//              color:#fff;
//         }

//         .staticrypt-footer p {
//             margin: 2px;
//             text-align: center;
//             float: right;
//         }

//         .staticrypt-footer a {
//             text-decoration: none;
//         }

//         label.staticrypt-remember {
//             display: flex;
//             align-items: center;
//             margin-bottom: 1em;
//         }

//         .staticrypt-remember input[type=checkbox] {
//             transform: scale(1.5);
//             margin-right: 1em;
//         }

//         .hidden {
//             display: none !important;
//         }

//         .staticrypt-spinner-container {
//             height: 100%;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//         }

//         .staticrypt-spinner {
//             display: inline-block;
//             width: 2rem;
//             height: 2rem;
//             vertical-align: text-bottom;
//             border: 0.25em solid gray;
//             border-right-color: transparent;
//             border-radius: 50%;
//             -webkit-animation: spinner-border .75s linear infinite;
//             animation: spinner-border .75s linear infinite;
//             animation-duration: 0.75s;
//             animation-timing-function: linear;
//             animation-delay: 0s;
//             animation-iteration-count: infinite;
//             animation-direction: normal;
//             animation-fill-mode: none;
//             animation-play-state: running;
//             animation-name: spinner-border;
//         }

//         @keyframes spinner-border {
//             100% {
//                 transform: rotate(360deg);
//             }
//         }
//     </style>
// </head>



