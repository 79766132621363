// -----------------------------------------------------------------------------
// Mobile specific
// -----------------------------------------------------------------------------

@import '../abstracts/functionsmixins';
@import '../abstracts/variables';

//burger menu
#overlay-hero__button {
  fill: $black;
  z-index: 2;
  cursor: pointer;
  user-select: none;

  // &.overlay-button__white {
  //   fill: $white;
  // }
  // &.overlay-button__black {
  //   fill: $black;
  // }

  &__exit{
    cursor: pointer;
    fill: $black;
  }
}

//burger menu and logo colour switch.
//see JS
#logo.inverted, #overlay-hero__button.inverted {
  filter: invert(1);
  transition: filter 1s;
}      
@include lg {
  .header-home #logo.inverted {
    filter: invert(0);
  }
}

#logo, #overlay-hero__button {
  filter: invert(0);
  transition: filter 1s;


}




//mobile menu v2

#menu-mobile__overlay {
  font-family: $h-font;
  
  transform: translateX(+100%);
  -webkit-transform: translateX(+100%);
  height: 100vh;
  width: 100vw;
  top: 0;
  background: rgb(209,145,20);
  background: linear-gradient(90deg, rgba(209,145,20,1) 0%, rgba(252,204,89,1) 100%);
  // opacity: 0;
  position: fixed;
  transition: opacity 0.5s ease-in-out;
  z-index: 11;
  //display: none;
  &.active {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    display: block;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
        align-items: flex-end;
    text-align: right;
    height: 100vh;
    list-style-type: none;
    padding-bottom: 100px;
    margin-right: 50px;

    li {
      cursor: pointer;
      padding: 0.5rem;
      line-height: 1.6;

      &:first-of-type {
        margin-bottom: $space-lg *0.25;
      }
      &:last-of-type {
        margin-top: $space-lg *0.25;
      }

      a {
        &.active {
          margin-left: 30px;
        }

        &.active:before {
          padding-right: 10px;
          border-bottom: 0;
          //border-left: 30px solid #222222;
          border-right: 0;
          border-top: 0;
          content: '>>';
          margin-left: -59px;
        }

        &:not(.active) {
          margin-left: 40px;
        }

        &:hover {
          border-bottom: 5px solid #222;
          border-left: 0;
          border-right: 0;
          border-top: 0;
        }
      }
    }
  }
}
// #menu-mobile__overlay {
//   position: absolute;
//   width: 100vw;
//   height: 100vh;
//   background: blue;
//   transform: translateX(-100%);
//   -webkit-transform: translateX(-100%);
//   z-index: 11;
// }

.slide-in {
  animation: slide-in 0.35s forwards;
  -webkit-animation: slide-in 0.35s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}
@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}
@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(+100%);
  }
}
@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(+100%);
  }
}
