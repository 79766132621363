// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
@use "sass:math";

@import "../abstracts/variables";
@import "../abstracts/functionsmixins";

header {
  //max-height: 1080px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
// 9 11 13
// Contact 12 13

.header {
  //note: relies on article's top margin
  &-home {
    background: url("../images/hero/planned-escape-hero-shoot-9v4-mobile-bw.jpg");
    // background: url("../images/hero/planned-escape-hero-shoot-9v3.jpg");
    @include mx-headerbg__cover;
    // filter: grayscale(100%);
    //background-position: left;
    background-color: #fbb622;
    min-height: 100vh;
    position: relative;
    // transition: 1s filter;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.7);
      transition: background-color 1s;
    }
    @include lg {
      background: url("../images/hero/planned-escape-hero-shoot-9v3.jpg");
      // filter: grayscale(0%);
      // background-blend-mode: luminosity;
      @include mx-headerbg__cover;
      min-height: 100vh;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.2);
        @include uxl {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
    }
    // BROAD trext and button colour update until later.
    //to do:  move btn to dark mode version
    @include max-lg {
      color: #d49314;

      .btn-primary {
        color: #d49314;
        border-color: #d49314;
      }

      .btn-primary:hover {
        color: $white;
        border-color: $white;
      }
    }
  }

  &-contact {
    background: url("../images/hero/planned-escape-hero-contact.jpg");
    @include mx-headerbg__cover;
    min-height: 100vh;
  }

  &-portfolio {
    background-color: $black;

    //pro crush
    &__crush {
      background: url("../project/procrush/large-hero.jpg");
      //background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9)), url("../project/procrush/procrush-hero.jpg");
      @include mx-headerbg__cover;
    }

    &__crush-code {
      background: url("../project/procrush-app/large-hero.jpg");
      //background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9)), url("../project/procrush/procrush-hero.jpg");
      @include mx-headerbg__cover;
    }

    &__divit {
      background: url("../project/divit/large-hero.jpg");
      //background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9)), url("../project/divit/large-hero.jpg");
      @include mx-headerbg__cover;
    }

    &__perfs {
      background: url("../project/perfectstranger/large-hero.jpg");
      @include mx-headerbg__cover;
    }

    &__ustr {
      background: url("../project/universalstore/large-hero.jpg");
      @include mx-headerbg__cover;
    }

    &__ustr-user-research {
      background: url("../project/universalstore-cart/large-hero.jpg");
      @include mx-headerbg__cover;
    }

    &__ustr-design-system {
      background: url("../project/universalstore-design-system/large-hero.jpg");
      @include mx-headerbg__cover;
    }

    &__oet {
      background: url("../project/oet/large-hero.jpg");
      @include mx-headerbg__cover;
    }
  }

  &-text {
    padding-bottom: 100px;
    max-width: 1900px;
    margin: 0 auto;
    width: 100%;
  }

  // Mobile menu has no height, so we need to add the top-margin to the hero @ mobile
  &-mobile-nav {
    position: fixed;
    top: $m-nav-t;
    right: $m-nav-r;
    z-index: 6;
    @include lg {
      display: none;
    }

    &__default {
      position: fixed;
    }

    &__alt {
      position: absolute;
    }
  }

  &-nav {
    display: none;
    margin-left: auto;
    //max-width: 440px;
    padding: 25px 50px 0 0;

    @include lg {
      display: flex;
    }

    &__project a,
    a {
      margin-left: 40px;
    }

    &__project {
      a {
        color: $white;

        &:hover {
          color: $white;
          border-bottom: 3px solid $white;
        }
      }
    }
  }
}

.site-header {
  display: flex;
  @include md {
    margin-bottom: 100px;
  }
}

.site-header__mobile {
  padding: 16px 13px 0;
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.site-header__top {
  position: fixed;
  top: 0;
  z-index: 1030;
  width: 100%;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////// HERO /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.hero {
  margin: 0 auto;
  overflow: hidden;
  position: relative;

  p {
    max-width: 40ch;
    @include lg {
      max-width: 50ch;
    }
  }

  .row {
    margin-left: 0;
    margin-right: 0;

    @include lg {
      background-color: transparent;
    }
  }

  @include sm {
    margin-bottom: $space-sm;
  }

  // @include md {
  //   margin-bottom: $space-xl;
  // }
}
