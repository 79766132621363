// -----------------------------------------------------------------------------
// This file contains styles applied to body or the entire page.
// ie. containers and grids
// -----------------------------------------------------------------------------
//Padding variables
@import '../abstracts/functionsmixins';
@import '../abstracts/variables';

body {
  @include md {
    font-size: 1.25rem;
  }
}

article {
  overflow: hidden;
  position: relative;

  &.a-about{
    margin-bottom: 0;
    
    padding-bottom: 0;
    @include md {
      padding-top: 0;
    }
  }
}

article,
footer {
  padding: $space-lg 0;
  @include xl {
    padding: $space-xl 0;
  }
}

footer,
header,
section {
  margin-left: auto;
  margin-right: auto;
}

.hero {
  //padding-top: $space-lg;
  overflow: hidden;
  position: relative;

  &#home{
    padding-bottom: $space-lg * 2;
  }
padding-top: 100px;
  &:not(#home){
    padding-bottom: $space-lg;
    padding-top: $space-lg;
  }

  @include xl {
    padding-top:0;
  }
}

.hero,
footer .container,
section {
  max-width: 1900px;
  width: 100%;
}

article section:not(:last-child, .widescreen) {
  margin-bottom: $space-md;
  @include lg {
    margin-bottom: $space-lg + $col-sp;
  }
}

.hero,
.widescreen-grid,
footer .container,
section {
  padding-left: $space-md;
  padding-right: $space-md;
  @include xl {
    padding-left: $space-lg;
    padding-right: $space-lg;
  }
}

section {
  &.contact-form {
    padding-top: 3rem;
  }
}

.container {
  padding: 0;
  margin: 0 auto;
  max-width: 1900px;
  @include lg {
    //padding: 0 150px;
  }
}

// .about {
//   // margin: 10vh 0 0;
//   // @include xl {
//   //   margin: 20vh 0 0;
//   // }
// }

.contact-header__text,
.portfolio-header__text {
  padding-top: 450px;
}

section.overlap {
  margin-top: -$space-lg;
  @include lg {
    margin-top: -$space-xl;
  }
}

section {
  &.colour-break {
    margin-bottom: $space-lg;

    &-light {
      background-color: #eee;
    }

    &__tp {
      padding-top: $space-lg;
    }

    &__bp {
      padding-bottom: $space-lg;
      @include lg {
        //padding-bottom: $space-lg/2;
      }
    }

    div.row {
      margin-top: $space-sm;
      margin-bottom: $space-sm;
    }
    @include lg {
      div.row {
        margin-top: $space-lg;
        margin-bottom: $space-lg;
      }
    }
  }

  &.widescreen {
    padding: 0;
    margin: 0;
    max-width: initial;
    //background: skyblue;
  }
}
