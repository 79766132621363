// -----------------------------------------------------------------------------
// Css animations and transitions
// -----------------------------------------------------------------------------
body {
  animation: bugfix infinite 1s;
  -webkit-animation: bugfix infinite 1s;
}
@keyframes bugfix {
  from {
    padding: 0;
  }

  to {
    padding: 0;
  }
}
@-webkit-keyframes bugfix {
  from {
    padding: 0;
  }

  to {
    padding: 0;
  }
}

// Scroll animation

// https://codepen.io/JonasNoldeDev/pen/BKMXwV
// @keyframes icon-scroll {
//   0%   { transform: translateY(0); }
//   25%  { transform: translateY(0.1em); }
//   50%  { transform: translateY(0); }
//   75%  { transform: translateY(0.2em); }
//   100% { transform: translateY(0); }
// }

@keyframes icon-scroll {
  0%   { transform: translateY(0); }
  10%   { transform: translateY(0); }
  20%  { transform: translateY(0.3em); }
  30%  { transform: translateY(0); }
  40%  { transform: translateY(0.3em); }
  50% { transform: translateY(0); }
  100% { transform: translateY(0); }
}
