@import "../abstracts/functionsmixins";
@import "../abstracts/variables";

.img-cover__left {
  object-position: left;
}

.problems-solved__list {
  .p__li:before {
    width: 16px;
    opacity: 0.3;
    display: block;
    position: relative;
    top: 28px;
    right: 30px;

    @include md {
      width: 32px;
      height: 32px;
      opacity: 0.3;
      display: block;
      right: 80px;
      position: relative;
      top: 40px;
    }
  }

  .p1:before {
    content: url(../images/circle-1.svg);
  }
  .p2:before {
    content: url(../images/circle-2.svg);
  }
  .p3:before {
    content: url(../images/circle-3.svg);
  }
  .p4:before {
    content: url(../images/circle-4.svg);
  }
}

#project section {
  margin-bottom: 0;
}

.project-hero {
  //margin-top: $space-lg / 2;
  &__text {
    color: #fff;

    a {
      color: #fff;
      border-color: #fff;
    }

    h5 {
      padding-top: 20px;
    }

    h6 {
      max-width: 55ch;
      margin-bottom: 50px;
    }
    // @include md {
    //   h1 {
    //     font-size: 100px;
    //     padding-bottom: 0;
    //   }
    // }
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: justify-content-start;

    div {
      margin-bottom: 10px;
    }
    @include md {
      flex-direction: row;
      flex-wrap: wrap;

      div {
        width: 50%;
        margin-bottom: $space-lg * 0.25;
      }
    }
    @include xl {
      flex-wrap: nowrap;
      justify-content: space-between;
      max-width: 1400px;
      margin-top: $space-lg * 0.25;

      div {
        width: auto;

        //margin-bottom: $space-lg/4;
      }
    }
  }
}

// .project {
//   //padding-bottom: 25vh;
// }

.project-gal {
  @include md {
    //margin-bottom: 30px;
  }

  &.gallery-video {
    video {
      width: 100%;
      max-height: 409px;
      background-color: rgba(0, 0, 0, 0.4);
      padding: 10px;
    }
  }
}

.project .collapse .row {
  margin-top: 100px;
}

.project-flex{

  // using .p-grid__col-full for full width images
  // &__solo-img {

  // }

  &__dual-imgs {
    display: flex;
    flex-wrap: wrap;
  
    video {
      //height: 100%;
      max-height: 525px;
      width: 100%;
      object-fit: cover;
      aspect-ratio: 17 / 10;
      display: block;
    }
  
    @include md {
      a {
        flex: 1 1 0;
        width: 0;
      }
      grid-column: 1 / 3;
      flex-wrap: nowrap;
      flex-direction: row;
      // div, a{
      //   max-width: 50%;
      // }
      img,
      video {
        // padding-bottom: initial;
        // margin-bottom: 25px;
        max-width: 850px;
      }
    }
  
    @include lg {
  
    }
  }

}



//put the zoom icon on all images in the gallery pagers, except the into image. A late decision to make mobile better.

[data-fancybox]::before {
  content: url(../images/img-zoom__black.svg);
  position: relative;
  display: block;
  text-align: right;
  top: 35px;
  right: 6px;
  opacity: 0.6;
}
