// -----------------------------------------------------------------------------
// This file contains all styles related to the manually made icons.
// -----------------------------------------------------------------------------
// https://stackoverflow.com/questions/15938933/creating-a-chevron-in-css

@import '../abstracts/functionsmixins';
@import '../abstracts/variables';

.icon-scroll, 
.icon-scroll__wheel-outer,
.icon-scroll__wheel-inner{
  display: none;
}

@include lg {
  .icon-scroll {
    height: 2.2em;
    width: 1.5em;
    border: 0.18em solid $black;
    border-radius: 1em;
    display: block;
    position: relative;
  }

  .hero .icon-scroll {
    margin-top: $space-md
  }

  .icon-scroll__wheel-outer {
    height: 1.1em;
    width: 0.3em;
    border-radius: 0.4em;
    margin-left: -3px;
    display: block;
    position: absolute;
    top: 0.3em;
    left: 50%;
    overflow: hidden;
  }

  .icon-scroll__wheel-inner {
    display: block;
    height: 50%;
    width: 100%;
    border-radius: inherit;
    background: $black;
    animation: icon-scroll 4s ease infinite;
  }
}


.chevron {
  position: relative;
  display: block;
  height: 50px;
  /*height should be double border*/
}

.chevron::after,
.chevron::before {
  position: absolute;
  display: block;
  content: "";
  border: 10px solid transparent;
  /*adjust size*/
}
/*Change the four instances of 'top' below to rotate (top/right/bottom/left)*/

.chevron::before {
  top: 0;
  border-top-color: $black;
  /*chevron Color*/
}

.chevron::after {
  top: -5px;
  /*adjust thickness*/
  border-top-color: $white;
  /*Match background colour*/
}

.chevron::after:hover {
  top: -5px;
  /*adjust thickness*/
  border-top-color: $white;
  /*Match background colour*/
}

#chevron {
  position: relative;
  text-align: center;
  //padding: 12px;
  //margin-bottom: 6px;
  height: 5px;
  width: 25px;
  display: inline-block;
  top: 9px;
  line-height: 1;
  margin-left: 15px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 52%;
    background: $black;
    transform: skew(0deg, -30deg);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 52%;
    background: black;
    transform: skew(0deg, 30deg);
  }
}

.btn:hover #chevron::after,
.btn:hover #chevron::before {
  background: $white;
}

.btn:focus #chevron::after,
.btn:focus #chevron::before {
  background: $white;
}

.btn.collapsed #chevron::after{
  transform: skew(0deg, -30deg);
}
.btn.collapsed #chevron::before {
  transform: skew(0deg, 30deg);
}


