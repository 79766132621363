// Grid //
@import '../abstracts/functionsmixins';
@import '../abstracts/variables';


.w-grid__txt, .w-grid__txt-r, .p-grid__txt, .p-grid__txt-r {
  margin-top: 75px;
  margin-bottom: $space-md;
}


.grid {
  &-img {
    min-height: 300px;

    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }
  }
  // home page
  &-split {
    &__text-img {
      display: grid;
      max-height: 1000px;
      @include lg {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-gap: 25px;

        div:nth-child(1) {
          grid-column: 1 / 3;
          grid-row: 2;
          z-index: 1;
          align-self: end;
        }

        div:nth-child(2) {
          grid-column: 4 / 7;
          grid-row: 1 / -1;
          text-align: right;
          overflow: hidden;

          img {
            object-fit: cover;
            height: 100%;
            max-height: 1000px;
          }
        }
      }
    }

    &__img-text {
      display: grid;
      max-height: 1000px;
      @include lg {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-gap: 25px;

        div:nth-child(1) {
          grid-column: 1 / 4;
          grid-row: 1 / -1;
          //text-align: right;
          overflow: hidden;

          img {
            object-fit: cover;
            height: 100%;
            max-height: 1000px;
          }
        }

        div:nth-child(2) {
          grid-column: 4 / 7;
          grid-row: 2;
          z-index: 1;
          align-self: end;
        }
      }
    }
  }
  /////////////// Project pages
  &-project {
    //margin-top: -100px;
    display: grid;
    grid-auto-rows: auto;
    //grid-row-gap: 100px;
    img {
      object-fit: cover;
      // overflow: hidden;
      //min-height: 320px;
      //max-height: 500px;
      height: 100%;
      width: 100%;

      @include sm {
        height: initial;
      }
    }

    .p-grid {
      &__img {
        &-l img {
          text-align: left;
          object-position: left;
        }

        &-r img {
          text-align: left;
          object-position: right;
        }
      }

      // &__txt {
      //   margin-top: $space-sm;
      //   margin-bottom: $space-md;
      // }

      &__txt-r {
        // margin-top: $space-sm;
        // margin-bottom: $space-md;

        @include md {
          grid-column: 2 / 3;
        }
      }

    }
    @include lg {
      grid-template-columns: 1fr 1fr;

      .p-grid {
        &__row {
          &-1 {
            grid-row: 1;
          }

          &-2 {
            grid-row: 2;
          }

          &-3 {
            grid-row: 3;
          }

          &-4 {
            grid-row: 4;
          }

          &-5 {
            grid-row: 5;
          }

          &-1to3 {
            grid-row: 1 / 3;
          }

          &-4to6 {
            grid-row: 4 / 6;
          }
        }

        &__col {
          &-l {
            grid-column: 1 / 2;
          }

          &-r {
            grid-column: 2 / -1;
          }

          &-full {
            grid-column: 1 / -1;
          }
          // &-r-2thirds {
          //   grid-column: 3 / -1;
          // }
        }

        &__title {
          z-index: 1;
          align-self: center;
        }
      }
    }
  }
}




.widescreen-grid {
  display: grid;
  //grid-row-gap: 40px;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;

  img {
    object-fit: cover;
    // overflow: hidden;
    //min-height: 320px;
    //max-height: 500px;
    height: 100%;
    width: 100%;

    @include sm {
      height: initial;
    }
  }

  &__coloured {
    display: grid;
    grid-auto-columns: auto;
    grid-template-rows: 250px 250px auto 250px 250px;
  }

  &__coloured-sml {
    display: grid;
    grid-auto-columns: auto;
    grid-template-rows: 250px 250px auto;
  }

  &__coloured-bg {
    grid-row: 2 / 5;
    grid-column: 1 / -1;
    background-color: rgba(206, 206, 206, 0.25);
  }

  .w-grid {
    &__img {
      &-l img {
        text-align: left;
        object-position: left;
      }

      &-r img {
        text-align: right;
        object-position: right;
      }
    }
  }


  @include lg {

    &__lg {
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 250px 250px auto 250px auto;
      //grid-template-rows: 250px 250px auto 250px 250px;
    }

    &__sml {
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 250px 250px auto;
    }
    
    &__coloured-bg {
      grid-row: 2 / 5;
    }

    .w-grid {
      &__row {
        &-1 {
          grid-row: 1;
        }

        &-2 {
          grid-row: 2;
        }

        &-3 {
          grid-row: 3;
        }

        &-4 {
          grid-row: 4;
        }

        &-5 {
          grid-row: 5;
        }

        &-1to3 {
          grid-row: 1 / 3;
        }

        &-4to6 {
          grid-row: 4 / 6;
        }
      }

      &__col {
        &-l {
          grid-column: 1 / 4;
        }

        &-r {
          grid-column: 4 / -1;
        }

        &-full {
          grid-column: 1 / -1;
        }

        &-r-2thirds {
          grid-column: 3 / -1;
          img {
            height: 100%;
          }
        }
      }

      &__title {
        z-index: 1;
        align-self: center;
      }
    }
  }
}

//specfic override
// .widescreen-grid .w-grid__col-r-2thirds img {
//   height: 100%;
// }