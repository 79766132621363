// -----------------------------------------------------------------------------
// This file contains styles applied to components not in their own sheets.
// ie. icons, overlays, progress bar, etc.
// -----------------------------------------------------------------------------
@import "../abstracts/functionsmixins";
@import "../abstracts/variables";

.page-resume {
  padding-top: $space-sm;
  padding-bottom: $space-lg;
}

.logo {
  z-index: 9;
  left: $m-nav-l;
  top: $m-nav-t;

  img {
    height: 45px;
    vertical-align: top;
  }
  @include lg {
    //width: 200px;
    img {
      height: 200px;
    }
  }

  &__default {
    position: fixed;
  }

  &__white {
    filter: brightness(1000%);
  }

  &__alt {
    position: absolute;
  }
}

.disabled {
  cursor: default;
  opacity: 0.8;
}

.about-portrait {
  background: url("../images/jw-portrait__mobile.jpg");
  @include mx-headerbg__cover;
  //background-position: left;
  min-height: 50vh;
  height: 100%;
  @include md {
    background: url("../images/jw-portrait.jpg");
    @include mx-headerbg__cover;
  }
  // @include lg {
  //   background: url("../images/jw-portraitv3.jpg");
  //   @include mx-headerbg__cover;
  //   //background-position: right;
  // }
  @include xl {
    background: url("../images/jw-portraitv4.jpg");
    @include mx-headerbg__cover;
    //background-position: right;
  }

  &__resume {
    background: url("../images/product-website.jpg");
    @include mx-headerbg__cover;
    background-size: auto;
  }
}

.thankyou-portrait {
  background: url("../images/product-branding.jpg");
  @include mx-headerbg__cover;
  background-size: auto;
}

.credits {
  margin-top: 3.5rem;
  text-align: center;
  font-size: 0.9rem;
  font-weight: bold;
  @include md {
    text-align: right;
  }
}

.passworded {
  display: inline-block;
  padding: 2px 4px 2px 4px;
  font-size: 0.7rem;
  text-transform:uppercase;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: $black;
  background-color: $darkmode-highlight;
}
