// -----------------------------------------------------------------------------
// This file contains all application-wide navigation component styles.
// -----------------------------------------------------------------------------
// for general text links, other links can be done specifically.
@use '../components/button';
@import '../abstracts/functionsmixins';
@import '../abstracts/variables';


nav {
  z-index: 1;
}

.nav-items {
  padding-bottom: 3px;
}

nav a,
.footer-nav a {
  //offset underline:
  padding-bottom: 3px;
}

nav a:hover,
.footer-nav a:hover {
  padding-bottom: 0;
  text-decoration: none;
}

.footer-nav {
  display: none;
  flex-direction: column;
  margin: 15px 0;
  justify-content: space-between;

  @include xl {
    display: flex;
    text-align: right;
  }

  @media (min-width: 1200px) {
    .footer-nav {
      display: flex;
      text-align: right;
    }
  }
}
