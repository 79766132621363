// -----------------------------------------------------------------------------
// This file contains styles applied to forms.
// ie. form group, form control, selectdiv
// -----------------------------------------------------------------------------
@import '../abstracts/functionsmixins';
@import '../abstracts/variables';

input,
textarea {
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: 0;
  cursor: text;
  width: 100%;
  overflow: auto;
}

.form-row {
  padding-bottom: 30px;
}

input[type]:not([type="checkbox"]):not([type="radio"]),
textarea.form-control {
  background-color: #eee;
  font-family: $b-font;
  border-radius: 0;
  border: 0;
  //padding-bottom: 6px;
  border-bottom: 4px solid #eee;
  padding: 0.5rem 1rem 0.375rem;
}

input[type]:not([type="checkbox"]):not([type="radio"]):focus,
.form-control:focus {
  color: #495057;
  background-color: #eee;
  border-bottom: 4px solid #222222;
  outline: 0;
  box-shadow: none;
}

.form-control::placeholder {
  opacity: 0.7;
}

form div {
  @include xl {
    padding-bottom: 20px;
  }
}
