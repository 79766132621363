// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
@import "../abstracts/functionsmixins";
@import "../abstracts/variables";

// :focus, button, .btn:focus, .btn:active {
//   outline: none !important;
//   box-shadow: none !important;
// }

// .zoom::before {
//   // content: 'zoom';
//   content: url(../images/img-zoom__black.svg);
//   position: relative;
//   display: block;
//   text-align: right;
//   top: 35px;
//   right: 11px;
// }

// .zoom__white::before {
//   // content: 'zoom';
//   content: url(../images/img-zoom__white.svg);
//   position: relative;
//   display: block;
//   text-align: right;
//   top: 35px;
//   right: 11px;
// }

button {
  background-color: transparent;
  border: 0;
  padding: 0;
}

.btn-primary {
  //font-size: 0.7rem;
  text-transform: uppercase;
  line-height: 2.3rem;
  font-family: $b-font;
  font-weight: 700;
  background: transparent;
  border: 4px solid;
  padding: 0px 19px;
  //margin-right: 50px;

  @include sm {
    font-size: 1.2rem;
    padding: 0px 19px;
  }

  @include md {
    //font-size: 1.5rem;
    padding: 5px 25px 3px;
  }
  &__black {
    color: $black;
    border-color: $black;
  }
  &__alt {
    color: $black;
    border-color: $black;
    background-color: $darkmode-highlight;
  }
}

.btn-footer {
  margin: 0 auto 15px;
  @include md {
    margin-right: 50px;
  }
}

.text-link {
  //color: $black;
  border-bottom: 3px solid $darkmode-highlight;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  padding-bottom: 0; // help offset

  &:hover {
    color: $link-hover;
    //border-bottom: 3px solid $link-hover;
  }
}

.btn-underline,
nav a:hover,
.footer-nav a:hover {
  color: $black;
  border-bottom: 3px solid $black;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  padding-bottom: 0; // help offset
}

.btn-social {
  width: 40px;
  max-width: 100%;
  overflow: visible;
  object-fit: contain;

  &:not(:last-of-type) {
    margin-right: 20px;
  }

  &:hover {
    opacity: 0.8;
    color: $link-hover;
    //darkmode-text
    //filter: invert(64%) sepia(85%) saturate(2628%) hue-rotate(7deg) brightness(97%) contrast(84%);
  }
  // a {
  //   min-width: 30px;
  //   img {
  //     min-width: 30px;
  //   }
  // }
}

.btn-primary:hover {
  color: $white;
  background-color: rgba(0, 0, 0, 0.5);
  border-color: #000;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
  //box-shadow: 0 0 0 0.2rem rgba(171,171,171, 0.5);
  box-shadow: 0 0 0 0.2rem #d19114;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:focus {
  color: $white;
  //background-color: #d19114;
  //background: linear-gradient(90deg, #d19114 0%, #fccc59 100%);
  background-color: rgba(0, 0, 0, 0.5);
  border-color: #000;
}

._ffn_textNode {
  border-bottom: 0px solid transparent !important;
}
