@import '../abstracts/functionsmixins';
@import '../abstracts/variables';


#portfolio-title {
  @include md {
    align-content: center;
    h2, p{
      text-align: center;
    }
  }
}

.portfolio-project .row {
  padding-top: 50px;
}

.header-portfolio .nav-items a {
  color: #fff;
}

.portfolio-menu {

  // display: flex;
  // justify-content: space-between;
  // margin: 0 0 25px;
  // //flex-direction: column;
  // flex-wrap: wrap;
  // flex-direction: row;

  // @include sm {
  //   padding: 0 10px;
  //   max-width: 520px;
  //   margin: 0 auto 25px;
  //   flex-wrap: wrap;
  //   flex-direction: row;

  //   h4 {
  //     line-height: normal;
  //     font-size: initial;
  //   }
  // }
  // @include md {
  //   padding: 0;
  //   margin: 0 auto 50px;
  // }
  
  // &__cat {
  //   box-sizing: border-box;
  //   background: none;
  //   padding: 2px 12px 2px 12px;
  //   line-height: 1.4;
  //   width: fit-content;

  //   box-shadow:0px 0px 0px 1px $grey inset; //inside border.
  //   //border: solid 1px #000; //issue with movement
  //   border-radius: 3px;
  //   margin-bottom: 12px;

  //   @include sm {
  //     box-shadow: none;
  //   }

  //   &.is-checked {
  //     background: $grey-light;
  //     box-shadow:0px 0px 0px 1px $black inset; //inside border.
  //     //border: solid 1px #000; //issue with movement
  //     border-radius: 3px;
  //   }
  // }


  display: flex;
  margin: 0 0 25px;
  //flex-direction: column;
  flex-wrap: wrap;
  flex-direction: row;
  

  @include sm {
    padding: 0 10px;
    max-width: 520px;
    margin: 0 auto 25px;
    justify-content: space-between;

    h4 {
      line-height: normal;
      font-size: initial;
    }
  }
  @include md {
    padding: 0;
    margin: 0 auto 50px;
  }

  &__cat {
    margin: 0 3px 0 0;
    box-sizing: border-box;
    background: none;
    padding: 4px 13px 3px 13px;
    line-height: 1.4;
    width: fit-content;
    text-align: center;
    //box-shadow: 0px 0px 0px 1px $grey inset; //inside border.
    border: solid 1px #cccccc; //issue with movement
    border-radius: 3px;
    margin-bottom: 12px;

    @include sm {
      padding: 2px 12px 2px 12px;
      box-shadow: none;
    }

    &.is-checked {
      background: $grey-light;
      box-shadow:0px 0px 0px 1px $black inset; //inside border.
      //border: solid 1px #000; //issue with movement
      border-radius: 3px;
    }
  }
}

.element-item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  float: left;
  width: 100%;
  height: 208px;
  padding: 15px;
  margin-bottom: 15px;
  line-height: 26px;

  &:hover:not(.disabled) h3 {
    color: $darkmode-text;
  }

  .name {
    position: relative;
    left: 0;
    top: 50%;
    //width: 75%;
    color: $white;

    @include md{
      top:40%;
    }

    @include lg {
      top: 60%;
      left: 0;
      bottom: 15%;
      //width: 80%;
    }

    span {
      opacity: 0.8;
    }
    //end name
  }

  @include sm-lg {
    &:nth-child(odd) {
      margin-right: 5px;
    }
  }

  @include sm {
    padding: 30px;
  }

  @include md {
    width: 49%;
    margin-bottom: 5px;
  }

  @include lg {
    width: 32.333%;
    height: 300px;
    padding: 30px;

    &:not(:nth-child(3n)) {
      margin: 0 1% 1% 0;
    }
  }

  &__divit {
    background-image: url("../project/divit/thumb.jpg");
  }

  &__crush {
    background-image: url("../project/procrush/thumb.jpg");
  }
  
  &__crush-code {
    background-image: url("../project/procrush-app/thumb.jpg");
  }

  &__ustr {
    background-image: url("../project/universalstore/thumb.jpg");
  }

  &__ustr-design-system {
    background-image: url("../project/universalstore-design-system/thumb.jpg");
  }

  &__ustr-cart {
    background-image: url("../project/universalstore-cart/thumb.jpg");
  }

  &__oet {
    background-image: url("../project/oet/thumb.jpg");
  }

  &__perfs {
    background-image: url("../project/perfectstranger/thumb.jpg");
  }

  &__coming-soon {
    background-image: url("../project/placeholder/thumb.jpg");
  }

}
