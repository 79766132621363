// -----------------------------------------------------------------------------
// This file contains all application-wide typography settings.
// note: colour and body set via _bootstrap_theme and _variables.
// -----------------------------------------------------------------------------
@import "../abstracts/functionsmixins";
@import "../abstracts/variables";

@font-face {
  font-family: "Biennale";
  src: url("../fonts/biennale-medium.eot");
  src: url("../fonts/biennale-medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/biennale-medium.woff2") format("woff2"),
    url("../fonts/biennale-medium.woff") format("woff"),
    url("../fonts/biennale-medium.ttf") format("truetype"),
    url("../fonts/biennale-medium.svg#ciclefina") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Biennale";
  src: url("../fonts/biennale-semibold.eot");
  src: url("../fonts/biennale-semibold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/biennale-semibold.woff2") format("woff2"),
    url("../fonts/biennale-semibold.woff") format("woff"),
    url("../fonts/biennale-semibold.ttf") format("truetype"),
    url("../fonts/biennale-semibold.svg#ciclefina") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Biennale";
  src: url("../fonts/biennale-bold.eot");
  src: url("../fonts/biennale-bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/biennale-bold.woff2") format("woff2"),
    url("../fonts/biennale-bold.woff") format("woff"),
    url("../fonts/biennale-bold.ttf") format("truetype"),
    url("../fonts/biennale-bold.svg#ciclefina") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

a {
  color: $black;
  text-decoration: none;
}

p {
  max-width: 65ch;
  @include md {
    margin-bottom: 2rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

h1 {
  font-family: $h-font;
  font-size: 2.6rem;
  line-height: 1.25;
  font-weight: 700;
  max-width: 12ch;
  margin-bottom: 30px;
  letter-spacing: -1px;
  @include sm {
    font-size: 3.1rem;
  }
  @include md {
    font-size: 8vw;
    margin-bottom: 50px;
  }
  @include xl {
    font-size: 6.875rem;
  }
}

h2 {
  font-family: $h-font;
  font-size: 2.75rem;
  line-height: 1.1;
  padding-bottom: 25px;
  font-weight: 700;
  letter-spacing: -1px;
  @include sm {
    font-size: 3rem;
  }
  @include lg {
    font-size: 6vw;
  }
  @include xl {
    font-size: 5rem;
  }
}

.project h2 {
  font-weight: 500;
}
// .project h2,
// .project h3 {
//   color: $grey;
// }

h3 {
  font-family: $h-font;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  @include md {
    font-size: 1.6rem;
    //margin-bottom: 3rem;
    margin-bottom: 1.7rem;
  }
}

.element-item {
  h3 {
    font-family: $b-font;
    font-weight: 700;
    font-size: 1.2rem;
    margin: 0;

    @include sm {
      font-size: 1.4rem;
    }

    @include xl {
      font-size: 1.6rem;
    }
  }

  .name span {
    font-size: 0.9rem;
    text-transform: uppercase;
  }
}
//portfolio heading and underline button
h4,
.footer-nav {
  font-size: 16px;
  font-family: $b-font;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: $lspc;
}

h5 {
  font-size: 16px;
  font-family: $h-font;
  font-weight: 700;
  letter-spacing: $lspc;
  text-transform: uppercase;
}

h6 {
  //basically bolded body
  font-size: 1.1rem;
  font-family: $b-font;
  font-weight: 600; //?? not sure why this works.
  line-height: 1.9;
  margin-bottom: 1rem;
  letter-spacing: 0.4px;
  @include md {
    font-size: 1.25rem;
  }
}

.highlight,
.about-list {
  max-width: 550px;
  padding-bottom: $space-md;
  @include md {
    padding-right: $space-sm;
  }
  @include lg {
    padding-right: $space-md;
  }
}

.highlight {
  
  font-family: $h-font;
  font-weight: 500;
  font-size: 1.3rem;

  @include md {
    //text-align: right;
    font-size: 1.4rem;
    letter-spacing: 1px;
  }

  @include lg {
    letter-spacing: 0px;
    //text-align: right;
    margin-top: -3px;
  }
}

.about-list {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-family: $h-font;
}

#menu-mobile__overlay ul li a {
  font-size: 2rem;
  font-family: $h-font;
  color: $black;
  text-decoration: none;
  font-weight: 800;
}

.portfolio-menu {
  @extend h4;
  color: $black;
  text-align: center;
}

.portfolio-menu .portfolio-menu__cat {
  //based on h4 a bit
  font-size: 12px; 
  font-family: $b-font;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: $lspc;
  color: $black;
  @include md {
    font-size: 0.9rem;
  }
}

// .portfolio-menu,
// .portfolio-menu .portfolio-menu__cat {
//   @extend h4;
//   color: $black;
//   text-align: center;
// }

.nav-items {
  font-weight: 800;
  letter-spacing: normal;
  font-family: $b-font;
  font-size: 1rem;

  &.header-nav__project {
    font-weight: 600;
  }
}
