// -----------------------------------------------------------------------------
// This file sets styles for Bootstrap in a Theming fashion.
// ie. core overrides done here.
// -----------------------------------------------------------------------------

@import '../../abstracts/variables';

/// set new defualt styles

$font-size-base:           1.2rem;
$font-size-lg:             $font-size-base * 1.3;
$line-height-base:         1.6;
$link-color:               $link;
$link-hover-color:         $link-hover;
$body-bg:                  #F6F6F6;
$body-color:               $black;
$headings-font-family:    $h-font;
$font-family-base:        $b-font;


$input-btn-padding-x:      0rem;
$input-btn-font-family:    $h-font;
$btn-border-radius:        0px;

$paragraph-margin-bottom:   2rem;

$lead-font-size:            1rem;
$lead-font-weight:          400;

$btn-focus-width:           0;
$btn-focus-box-shadow:      none;

// manual imports
.collapse.in {
    display: block;
}
