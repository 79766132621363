@charset "UTF-8";

// 1. Configuration and helpers.
//@import '../abstracts/variables';
//@import '../abstracts/functionsmixins';

// // 2. Vendors
@use './vendors/bootstrap-theme/bootstrap-theme'; //note: pulls in bootstrap within.
@use './vendors/fancybox/fancybox.3.5.7'; //note: pulls in bootstrap within.

// // 3. Base stuff
@use './base/base';
@use './base/fonts';
@use './base/typography';
@use './base/helpers';

// 4. Layout-related sections
@use './layout/header';
@use './layout/body';
@use './layout/footer';
@use './layout/grid';

// 5. Components
@use './components/button';
@use './components/nav';
@use './components/list';
@use './components/animation';
@use './components/form';
@use './components/components';
@use './components/mobile';
@use './components/portfolio';
@use './components/icons';
@use './components/patterns';

// 6. Page-specific styles
@use './pages/project';
