// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
//@use 'functionsmixins'; //resets, variables, mixins, and some utility CSS classes
$black: #222222;
$white: #FFFFFF;
$grey: rgba(22,22,22,0.5);
$grey-light: rgba(22,22,22,0.2);
$grey-lighter: rgba(22,22,22,0.1);
$highlight: #9a3415;
$darkmode-highlight: #fbb622;
$darkmode-text: #d49314;
$link: $black;
$link-hover: $grey-light;
$h-font: 'Biennale', sans-serif;
$b-font: 'Lato', sans-serif;
$lspc: 2px;
$m-nav-t: 27px;
$m-nav-r: 15px;
$m-nav-l: 15px;
$space-lg: 75px; // was 20vh but inconsistent

$space: 100px;
$space-sm: calc($space * 0.25);
$space-md: calc($space * 0.5);
$space-lg: calc($space * 1);
$space-xl: calc($space * 2);
//@import 'vendors/bootstrap/scss/bootstrap'; //causes issues. 
$col-sp: 15px;  //$card-spacer-y; //see bootstrap
