// -----------------------------------------------------------------------------
// This file contains all functions for use within scss.
// -----------------------------------------------------------------------------
// RESPONSIVE
// Any changes to these breakpoints should be also updated via bootstrap-overrides
// Small tablets and large smartphones (landscape view)

$screen-sm-min: 576px; // Small tablets (portrait view)
$screen-md-min: 768px; // Tablets and small desktops
$screen-lg-min: 992px; // Large tablets and desktops
$screen-xl-min: 1200px; // HD devices
$screen-uxl-min: 1600px; // Ultra mega bad boy devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}
@mixin sm-lg {
  @media (min-width: #{$screen-sm-min}) and (max-width: #{$screen-lg-min}) {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}
// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}
// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Extra large devices
@mixin uxl {
  @media (min-width: #{$screen-uxl-min}) {
    @content;
  }
}
// Custom devices ie "@include rwd(1400) {...}"
@mixin rwd($screen) {
  @media (min-width: ($screen + 'px')) {
    @content;
  }
}

@mixin mx-headerbg__cover {
  //has to come after background image.
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

////max

// Extra large devices
@mixin max-lg {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}
