// -----------------------------------------------------------------------------
// This file contains styles applied to lists.
// ie. ul, li, ol
// -----------------------------------------------------------------------------
@import "../abstracts/functionsmixins";
@import "../abstracts/variables";

.portfolio-problems-solved__ol {
  li:not(:last-child) {
    margin-bottom: 3px;
  }
}

.no-deco {
  list-style: none;
}

//ul
.about-list {
  display: flex;
  flex-wrap: wrap;

  &li {
    width: fit-content;
    padding-right: 20px;
  }

  @include md {
    flex-direction: column;
    align-items: end;
  }
}

section.colour-break .about-dates .row {
  margin: 0 0 20px 0;
}

// .about-list li{
//     display: inline;
//     list-style: initial;

//     @include md {
//         display: inline;
//         text-align: right;
//         list-style: none;
//     }
// }
