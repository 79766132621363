// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
@import '../abstracts/functionsmixins';
@import '../abstracts/variables';

footer {
  background-color: $grey-lighter;
  text-align: center;
  @include md {
    text-align: left;
  }
}


